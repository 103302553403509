<template>
    <div class="page page-about-us">
        <Banner />

        <div class="container">
            <div class="people">{{ $t('aboutUs.people') }}</div>
            <div class="office-team">{{ $t('aboutUs.officeTeam') }}</div>
            <div class="desc">{{ $t('aboutUs.desc') }}</div>

            <div class="people-team">
                <div
                    class="person"
                    v-for="(person, index) in people" :key="index"
                >
                    <div class="image-wrapper">
                        <img :src="person.photo" :alt="person.name" />
                    </div>

                    <div class="person-info-wrapper">
                        <div class="person-name">{{ person.name }}</div>
                        <div class="person-position">{{ $t(person.title) }}</div>
                        <div class="person-email">{{ person.email }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from "../components/homepage/Banner.vue";
import bohac from "../assets/team/bohac.png"
import vajda from "../assets/team/vajda.png"
import kozubik from "../assets/team/kozubik.png"
import woman from "../assets/team/woman.png"
import cula from "../assets/team/cula.png"

export default {
    name: "PageAboutUs",
    components: {
        Banner
    },
    data() {
        return {
            people: [
                {
                    photo: bohac,
                    name: 'ANDREJ BOHÁČ',
                    title: 'aboutUs.generalDirector',
                    email: 'bohac@carsen.sk'
                },
                {
                    photo: vajda,
                    name: 'PAVEL VAJDA',
                    title: 'aboutUs.businessDevelopmentDirector',
                    email: 'vajda@carsen.sk'
                },
                {
                    photo: kozubik,
                    name: 'MICHAL KOZUBÍK',
                    title: 'aboutUs.financeManager',
                    email: 'kozubik@carsen.sk'
                },
                {
                    photo: woman,
                    name: 'MARTINA VASILOVÁ',
                    title: 'aboutUs.accountSalesManager',
                    email: 'vasilova@carsen.sk'
                },
                {
                    photo: cula,
                    name: 'MARTIN ČULA',
                    title: 'aboutUs.officeManager',
                    email: 'cula@carsen.sk'
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.page-about-us {
    .people {
        color: $heading-gray;
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 50px;
    }

    .office-team {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        color: $heading-gray;
    }

    .desc {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .people-team {
        margin-bottom: 50px;
    }
    .person {
        display: flex;
        gap: 10px;
        margin: 20px 0;

        .person-info-wrapper {
            color: $heading-gray;

            .person-name {
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .person-position {
                padding-top: 2px;
                font-size: 14px;
            }

            .person-email {
                font-size: 14px;
            }
        }
    }
}
</style>